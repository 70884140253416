.header .container {
    width: 100%;
    padding: 0;
}

.logo svg {
    width: 150px;
    height: 40px;
}

.menuleftside {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menubutton.MuiButtonBase-root {
    margin-right: 30px;
    padding: 0;
}

.menubutton.MuiButtonBase-root svg {
    width: 30px;
    height: 30px;
}
.usermenu{
    display: flex;
    align-items: center;
    gap: 8px; 
}
.usermenu .MuiTypography-body1{display:flex;align-items:center; gap:5px;}
.usermenu .MuiTypography-body1 svg{fill:#04B983;font-size: 25px;}