.breadcrumbs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top:77px;
    background: #fff;
    padding: 20px 0;
    z-index:99;
}
.createingbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:20px;
}
.createingbar .MuiInputBase-root{height:40px;}

.information {
    background: #FFFFFF;
    border: 1px solid #CBE2D9;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px;
}

.information .MuiSvgIcon-root {
    cursor: pointer;
}

.information .MuiSvgIcon-root:first-child {
    border-right: 1px solid #CBE2D9;
    padding-right: 13px;
    margin-right: 13px;
}

.all-exhibitions {
    background: #FFFFFF;
    border: 1px solid #CBE2D9;
    border-radius: 10px;
    margin-bottom:20px;
}

.cardspace {
    display: flex;
    padding: 20px;
    gap: 20px;
    flex-flow:wrap;
}
.cardspace p{
    display:flex;
     gap:10px;
     align-items:center;
}
.cardspace span{
    font-size:16px;
}
.createnewexhibition{display:flex;gap:20px;margin-bottom:20px;}
.createnewexhibition .MuiBox-root{flex:1;}
.cardaction{position:absolute !important; top:10px; right:10px;z-index:9;}
.daterange-validation{display:flex; width: 50%;gap:15px;}
.daterange-validation .MuiStack-root .MuiFormControl-root{min-width:175px;}
.all-exhibitions{min-height:64vh;}


