.snackbar .MuiPaper-root {
    gap: 10px;
    align-items: center;
    padding: 14px 30px;
    background-color: #E2374B;
}

.snackbar .MuiAlert-action,
.snackbar .MuiButtonBase-root,
.snackbar .MuiAlert-message,
.snackbar .MuiAlert-icon {
    padding: 0;
    margin: 0;
    color: #fff !important;

}