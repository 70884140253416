.all-exhibitions.company-detailstable {
    width: auto;
    overflow-y: auto;
    height: 64vh;
}

.company-detailstable .MuiTable-root {
    margin-bottom: 10px;
}
.company-detailstable .MuiTableCell-head{padding: 15px 5px 10px 5px;}
.company-detailstable .MuiTableHead-root{position: sticky;top:0px;background: #fff;
    z-index: 1;}

.company-detailstable .MuiTableCell-root:first-child {
    color: #04B983;
    font-weight: 700;
    padding-left:15px;
}

.company-detailstable th.MuiTableCell-root:first-child {
    color: #494A4C;
}
.company-detailstable .MuiTableCell-root:nth-child(2) {
  -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width:200px;
    height: 17px;
    display: -webkit-inline-box;
}
.company-detailstable .MuiTableCell-root:nth-child(6) {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width:250px;
    height: 17px;
    display: -webkit-inline-box;   
}
@media screen and (max-width:1400px) {
    .all-exhibitions.company-detailstable{height:56vh;}
}

@media screen and (max-width:1300px) {
    .company-detailstable .MuiTableCell-root,
    .company-detailstable th.MuiTableCell-root{font-size:12px;}
    .company-detailstable .MuiTableCell-root:nth-child(2),
    .company-detailstable .MuiTableCell-root:nth-child(6){height:8px;}
}