.table-exhibitors .MuiTabs-scroller {
    background: #FFFFFF;
    border: 1px solid #CBE2D9;
    border-radius: 10px 10px 0 0;
    border-bottom: none;

}
.table-exhibitors .MuiTableCell-head{padding: 15px 5px 10px 5px;}
.table-exhibitors .MuiBox-root {
    padding: 0;
    border-color: #CBE2D9;
    position: sticky;
    top:160px;
    z-index:2;
}

.table-exhibitors .MuiBox-root:last-child {
    border-bottom: none;
}
.tableboder.MuiTableContainer-root {
    background: #FFFFFF;
    border: 1px solid #CBE2D9;
    border-top: none;
    border-radius: 0 0px 10px 10px;
    width: auto;
    margin-bottom: 20px;
    height:55vh;
}
.table-exhibitors .MuiTableHead-root {position: sticky; top:0; background: #FFFFFF;z-index:1;}
.table-exhibitors .MuiTable-root {
    margin-bottom: 10px;
}

.table-exhibitors .MuiTableCell-root:first-child {
    padding-left: 15px;
 }
 .table-exhibitors tbody .MuiTableCell-root:first-child{ font-weight: 700;
    color: #04B983;
}

.CompanyName-exibitors tbody .MuiTableCell-root:first-child{
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width:250px;
    height: 17px;
    display: -webkit-inline-box;  
}

 .table-exhibitors .MuiTableCell-root:nth-child(1) a {
    color: #04B983 !important;
    font-weight: 700;
}

.table-exhibitors th.MuiTableCell-root:nth-child(2) {
    color: #494A4C;
}

.table-exhibitors .MuiTableCell-root:last-child {
    padding-right:15px;
    
}
.table-exhibitors .visitortable .MuiTableCell-root:nth-child(2) {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 350px;
    height: 17px;
    display: -webkit-inline-box;
}
@media screen and (min-width:1920px) {
 .tableboder.MuiTableContainer-root {
    height:64vh;
}
}
@media screen and (max-width:1300px) {
.tableboder.MuiTableContainer-root{height: 45vh;}
.table-exhibitors .MuiTableCell-root.MuiTableCell-head,
.table-exhibitors .MuiTableCell-root.MuiTableCell-body{font-size:12px;}
.CompanyName-exibitors tbody .MuiTableCell-root:first-child,
.table-exhibitors .visitortable .MuiTableCell-root:nth-child(2){height:8px;}

}

