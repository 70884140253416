.loginscreen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    text-align: center;
}

.loginbox.MuiPaper-root {
    border: 1px solid #CBE2D9;
    padding: 50px;
    display: flex;
    flex-direction: column;
    width: 490px;
    gap: 20px;
    align-items: center;
    border-radius: 10px;
    background: #FFFFFF;
}
.loginloading{display:flex; flex-direction:column; gap:20px; width:100%;}
.skeletonloading {width:100%;}
.skeletonloading span.MuiSkeleton-root {
    width: 100%;
    height: 75px;
}
.loginscreen .MuiButtonBase-root {
    margin-top: 10px;
}

.loginscreen .loginbutton {
    width: 100%;
    background-color: #04B983;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 700;
    text-transform: capitalize;
    padding: 8px 22px;
    line-height: 24px;
    letter-spacing: 0;
    box-shadow: none;
    color: #fff;
}

.organizer-link {
    color: #04B983;
    cursor: pointer;
    text-decoration: underline;
}

.forgot-password {
    color: #04B983;
    cursor: pointer;
    text-decoration: underline;
}

.organizer-link:hover {
    text-decoration: none;
    /* Remove underline on hover if needed */
}

.fixed-form-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}